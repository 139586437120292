import { Box, Typography } from "@mui/material";
import { createTheme } from '@mui/material/styles';

// Cookies.set('isLogged', true, { expires: 7 });  
// const valorCookie = Cookies.get('nomeDoCookie');
// Cookies.remove('cookieExpiraEm');

export const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const stringAvatar = (name) => {
  if (name.includes(" ")) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0].toUpperCase()}${name.split(' ')[1][0].toUpperCase()}`,
    };
  } else {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name[0][0].toUpperCase()}${name[1][0].toUpperCase()}`,
    };
  }
};

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const toReal = (valor) => {
  if (valor) {
    return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  } else {
    return "0"
  }
}

export const toHour = (timestamp) => {
  let unixTimestamp = timestamp
  let date = new Date(unixTimestamp * 1000);
  let hours = "0" + date.getHours();
  let minutes = "0" + date.getMinutes();
  let formatTime = hours.substr(-2) + ':' + minutes.substr(-2);
  return formatTime
}

export const createNewTheme = (colorPrimary, colorSecondary, colorTertiary) => {
  return createTheme({
    palette: {
      primary: {
        main: `rgb(${colorPrimary})`,
      },
      secondary: {
        main: `rgb(${colorSecondary})`,
      },
      tertiary: {
        main: `rgb(${colorTertiary})`,
      }
    },
  });
};
